@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: 'Poppins', sans-serif !important;
}

.ant-menu-root .ant-menu-item {
  margin-bottom: 15px !important;
  height: 45px !important;
  margin-left: 5px;
}

.ant-menu-root .ant-menu-submenu {
  margin-bottom: 15px !important;
  margin-left: 4px;
}

.ant-card {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
}

.ant-btn {
  box-shadow: none !important;
}

.ant-menu-submenu.ant-menu-submenu-selected>.ant-menu-submenu-title {
  background-color: #EEEDFA;
  color: #6B43B5;
}

.ant-menu-submenu .ant-menu-item {
  background-color: transparent;
}

.ant-menu-submenu .ant-menu-item.ant-menu-item-selected {
  color: #6B43B5;
}

.ant-menu .ant-menu-item.sidebar_menuitem1 {
  background-color: transparent;
  color: black;
  font-size: 16px;
}

.ant-menu .ant-menu-item.sidebar_menuitem1.ant-menu-item-selected {
  background-color: #EEEDFA;
  color: #6B43B5;
}

.ant-table {
  /* border:1px solid #ECECEC; */
  border-radius: 8px;
}

.sidebar_menuitem1 {
  /* margin-bottom: 35px !important; */
}

.ant-menu-root .ant-menu-submenu {
  /* margin-bottom: 25px !important; */
  margin-left: 4px;
  gap: 10px !important;
  display: flex !important;
  flex-direction: column !important;
}

.ant-menu-root .ant-menu-item {
  /* margin-bottom: 35px !important; */
  height: 45px !important;
  margin-left: 5px;
  
}

.ant-menu .ant-menu-root .ant-menu-inline .ant-menu-dark .collapsedMenu {
  gap: 20px !important;
  display: flex !important;
  flex-direction: column !important;
}

/* Use higher specificity and !important to override Ant Design styles */
.ant-table-measure-row{
  display: none !important;
}
