.dentist_container {
  min-width: 275px;
  margin-bottom: 25px;
  max-height: 500px;
  overflow: auto;
  border-top: 5px solid #a677f6;
}
.dentist_cp {
  cursor: pointer;
}
.dentist_updating {
  padding: 40px;
  text-align: center;
}
.dentist_root {
  max-height: 400px;
  overflow: auto;
  display: flex;
  justify-content: left;
  display: flex;
}
