#main1 {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  background-color: #E4DCF5;
  display: table-cell;
  vertical-align: middle;
}

.fof .my_h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type .5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }

  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
.monster {
  width: 250px;
  height: 200px;
  background: #7A08FA;
  display: block;
  margin: 0 auto;
  position: relative;
}

.size1 {
  -webkit-transform: scale(.7);
  transform: scale(.7);
  top :100px;
  left:-1%
}

.monster:after {
  content: '.';
  display: block;
  width: 100px;
  height: 100px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  top: -60px;
  margin: auto;
  left: 0;
  right: 0;
  overflow: hidden;
  font-size: 160px;
  line-height: 10px;
  text-align: center;
  font-weight: bold;
  color: rgba(255, 255, 255, 0);
  text-shadow: 0 0 0 #333;
  -webkit-animation: eye 4s infinite linear;
  animation: eye 4s infinite linear;


  box-shadow: 0 5px 0 0 rgba(0, 0, 0, .3),
    0 215px 0 0px #7A08FA, 0 190px 0 -20px rgba(0, 0, 0, .3),



    0 5px 0 75px #7A08FA, 0 220px 0 0 #7A08FA, -75px 215px 0 0 #7A08FA, 75px 215px 0 0 #7A08FA,

    15px 135px 0 -30px #fff, -15px 135px 0 -30px #fff, -35px 138px 0 -35px #fff, 35px 138px 0 -35px #fff,

    0 200px 0 5px #7A08FA, -38px 200px 0 0px #7A08FA, 38px 200px 0 0px #7A08FA;
}

.monster:before {
  content: '';
  display: block;
  width: 150px;
  height: 50px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  margin: auto;

  box-shadow: inset 0 -22px 0 0 #000
}


@-webkit-keyframes eye {
  0% {
    text-shadow: 0 0 0 #333;
  }

  10% {
    text-shadow: 0 -30px 0 #333;
  }

  14% {
    text-shadow: 0 -30px 0 #333;
  }


  24% {
    text-shadow: 20px -25px 0 #333;
  }

  29% {
    text-shadow: 20px -25px 0 #333;
  }

  40% {
    text-shadow: -20px -25px 0 #333;
  }

  45% {
    text-shadow: -20px -25px 0 #333;
  }


  55% {
    text-shadow: 0 0 0 #333;
  }

  65% {
    text-shadow: 0 0 0 #333;
  }

  70% {
    text-shadow: 20px 0px 0 #333;
  }

  80% {
    text-shadow: 20px 0px 0 #333;
  }

  85% {
    text-shadow: 0px 0px 0 #333;
  }

}

@keyframes eye {
  0% {
    text-shadow: 0 0 0 #333;
  }

  10% {
    text-shadow: 0 -30px 0 #333;
  }

  14% {
    text-shadow: 0 -30px 0 #333;
  }


  24% {
    text-shadow: 20px -25px 0 #333;
  }

  29% {
    text-shadow: 20px -25px 0 #333;
  }

  40% {
    text-shadow: -20px -25px 0 #333;
  }

  45% {
    text-shadow: -20px -25px 0 #333;
  }


  55% {
    text-shadow: 0 0 0 #333;
  }

  65% {
    text-shadow: 0 0 0 #333;
  }

  70% {
    text-shadow: 20px 0px 0 #333;
  }

  80% {
    text-shadow: 20px 0px 0 #333;
  }

  85% {
    text-shadow: 0px 0px 0 #333;
  }

}