 
.ar_rangepicker {
    height: 32px;
}

.ar_mw100 {
    min-width: 100%;
}

.ar_inputid {
    width: 100%;
}

.ar_duedate {
    margin-top: 2%;
}

.ar_mr15 {
    margin-right: 15px;
}

.ar_slider {
    margin-left: 2px;
    width: 100%;
}

.ar_mt15 {
    margin-top: 15px;
}

.ar_tbcell1 {
    background: rgba(10, 175, 10, 0.1)
}

.ar_tbcell2 {
    background: rgba(255, 255, 0, 0.1)
}

.ar_tbcell3 {
    background: rgba(255, 165, 0, 0.1)
}

.ar_tbcell4 {
    background: rgba(255, 0, 0, 0.1)
} 

.ar_cursor {
    cursor: pointer;
}

.ar_paymentrequest {
    font-weight: bold;
    margin-top: 15px;
    margin-right: 5px;
    margin-left: 5px;
}

.ar_paymentrequest-send {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 5px
}

.ar_modal {
    min-width: fit-content
}

.card-main {
    width: 250px !important;
    height: 108px !important;
}

.vertical-divider {
    height: 60px;
    border: 1px solid rgba(183, 183, 183, 1);
    margin-right: 20px;
    margin-left: 40px;
}

.vertical-divider1 {
    height: 0px;
    margin-right: 20px;
    margin-left: 40px;
}

.vertical-divider2 {
    height: 60px;
    /* margin-right: 20px; */
    margin-left: 30px;
    border: 1px solid rgba(183, 183, 183, 1);;
}

@media (max-width: 1564px) {
    .vertical-divider2 {
      display: none;
    }
  }

.selected-filters {
    margin-bottom: 30px;
}

.details-button-color1 {
    color: #6b43b5 !important ;
    /* display: flex !important ; */
    align-items: center !important ;
    /* justify-content: flex-end !important ; */
    margin-left: auto !important;
  }

  .details-button-color1:hover {
    background-color: transparent !important;
    color: #6b43b5 !important ;
  }